import React from 'react';

const About = () => {
    return (
        <div className="jumbotron jumbotron-fluid">
        <div className="container">
            <h1 className="display-4">Information</h1>
            <p className="lead">Application version <strong>1.0.0</strong></p>
        </div>
        </div>
    );
};

export default About;